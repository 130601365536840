function Cover() {
  return (
    <>
      {/* cover section */}
      <section className="md:bg-pink-200 hero-section health-cash-hero">
        <div className="container">
          <div className="pill-item bg-green-400">Health Cash</div>
          <div className="grid justify-center gap-10 grid-cols-1 md:grid-cols-2 md:gap-20">
            <div>
              <h1>Get Cashback on hospital visits</h1>
              <p className="mr-0 md:mr-10 text-lg md:text-xl">
                We'll cover the cost whenever you get healthcare due to
                accidents
              </p>
              <a
                className="button button--black mt-6"
                href="https://insurance.casava.co/signup/health-cash/"
                target="_blank"
                rel="noreferrer"
              >
                <span>Buy Health Cash</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cover;
