function Cover() {
  return (
    <>
      <section className="md:bg-pink-200 hero-section health-hero">
        <div className="container">
          <div className="pill-item bg-green-400">Health Insurance</div>
          <div className="grid justify-center gap-10 grid-cols-1 md:grid-cols-2 md:gap-20">
            <div>
              <h1>
                Enjoy life <br /> with affordable <br /> healthcare
              </h1>
              <p className="mr-0 md:mr-10 text-lg md:text-xl">
                Get health insurance benefits with Casava health
              </p>
              <a
                className="button button--black mt-6"
                href="https://insurance.casava.co/signup/health/"
                target='_blank'
                rel='noreferrer'
              >
                <span>Buy Health Insurance</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cover;
