import Header from "../components/Common/Header";
import Faq from "../components/Common/Faq";
import Banner from "../components/Common/Banner";
import Footer from "../components/Common/Footer";
import Cover from "../components/Index/Cover";
import Experience from "../components/Index/Experience";
import GettingStarted from "../components/Index/GettingStarted";
import Testimonials from "../components/Index/Testimonials";
import faqData from '../config/faq.json';
import Reviews from '../components/Index/Reviews';
import Empowerment from '../components/Index/Empowerment';

function Landing() {

  let index  = Math.ceil(faqData["homeData"]['rows'].length/2);

  let rows = faqData["homeData"].rows;
  let data1 = {title:'',rows:[]};
  data1.rows = rows.slice(0,index);
  

  let data2 = {title:'',rows:[]};
  data2.rows = rows.slice(index,faqData["homeData"]['rows'].length);


  return (
    <div className='page landing-page'>
      <Header></Header>
      <Empowerment />
      <Cover></Cover>
      <GettingStarted></GettingStarted>
      <Experience></Experience>
      {/* <Testimonials></Testimonials> */}
      <Reviews />
      <Faq data1={data1} data2={data2}></Faq>
      <Banner></Banner>
      <Footer></Footer>
    </div>
  );
}

export default Landing;
