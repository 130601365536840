import icon_green_check from '../../assets/green-check.svg';

function EffortlessClaims() {
  return (
    <>
      <section
        className="health-steps-section"
        style={{ background: 'rgba(250 150 150 / 0.1)' }}
      >
        <div className="container">
          <div className="grid gap-10 grid-cols-1 md:grid-cols-2 md:gap-12">
            <div className="flex flex-col">
              <h2 className="mb-8">Effortless Claims, Unrivaled Support</h2>
              <p className="text-xl">
                Seamless Simplicity. Unparalleled Speed.
              </p>
              <div className="mt-8 hidden md:block">
                <a
                  href="/make-a-claim"
                  target="_blank"
                  className="button button--black"
                >
                  <span>Claim What's Yours</span>
                </a>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="step-item">
                <img src={icon_green_check} alt="checkmark" />
                <div className="ml-8">
                  <h5>Log In Effortlessly</h5>
                  <p>Your account is just a click away.</p>
                </div>
              </div>
              <div className="step-item">
                <img src={icon_green_check} alt="checkmark" />
                <div className="ml-8">
                  <h5>Claim in a Snap</h5>
                  <p>Navigate to "Make a Claim" and let us handle the rest.</p>
                </div>
              </div>
              <div className="step-item">
                <img src={icon_green_check} alt="checkmark" />
                <div className="ml-8">
                  <h5>Real-Time Tracking</h5>
                  <p>Stay informed, every step of the way.</p>
                </div>
              </div>
              <div className="step-item">
                <img src={icon_green_check} alt="checkmark" />
                <div className="ml-8">
                  <h5>Lightning-Fast Processing</h5>
                  <p>Claims settled within 48 hours.</p>
                </div>
              </div>
              <div className="mt-8 md:hidden">
                <a
                  href="/make-a-claim"
                  target="_blank"
                  className="button button--black"
                >
                  <span>Claim What's Yours</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EffortlessClaims;
