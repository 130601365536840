function HealthPerks(props) {
  return (
    <>
      {/* health perks section */}
      <section className="health-perks-section">
        <div className="container">
          <div className="flex flex-col items-center justify-center">
            <div className="w-full lg:w-2/3 lg:text-center">
              <h1 className="mb-6">{props.healthPerksInfo.title} </h1>
              <p className="text-xl">
                {props.healthPerksInfo.desc1} <br />{' '}
                {props.healthPerksInfo.desc2}{' '}
              </p>
            </div>
          </div>
          <div className="coverage-features coverage-features-two">
            {props.healthPerksInfo.perks_three.map((element, index) => {
              return (
                <div className="perk-item">
                  <img src={element.img} />
                  <h5>{element.label}</h5>
                  <p>{element.desc}</p>
                </div>
              );
            })}
          </div>
          {props.showBtn && (
            <div className="flex flex-col items-center justify-center">
              <a
                className="button button--black mt-6"
                href="https://insurance.casava.co/signup/health/"
                target="_blank"
                rel='noreferrer'
              >
                <span>Buy Now</span>
              </a>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default HealthPerks;
