function Cover() {
  return (
    <>
      <section className="hero-section md:bg-pink-300 event-board">
        <div className="container">
          <div className="grid justify-center gap-10 grid-cols-1 md:grid-cols-2 md:gap-20">
            <div>
              <h1>
                Congratulations on Your Event Cover for Insurance Meets Tech IMT
                3.0!
              </h1>
              <p className="text-lg md:text-xl mr-0 md:mr-24">
                We're thrilled to have you at the Insurance Meets Tech 2024
                event. Your attendance is covered by Casava's comprehensive
                accident insurance, ensuring your peace of mind throughout the
                day.
              </p>
              <a
                href="/make-a-claim"
                target="_blank"
                className="button button--white mt-5"
              >
                <span>Make a Claim</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cover;
