import icon_user_avatar from '../assets/avatar.png';
import { useFormik } from 'formik';
import Header from '../components/Common/Header';
import Footer from '../components/Common/Footer';
import * as Yup from 'yup';
import { useState } from 'react';

export default function MakeAClaimPage() {
  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    contactNumber: Yup.string().required('Contact Number is required'),
    claim: Yup.string().required('Claim is required'),
    incidentTime: Yup.string().required('Incident Time is required'),
    location: Yup.string().required('Location is required'),
    claimDocumentSubmission1: Yup.string().required(
      'Claim Document Submission is required'
    ),
    claimDocumentSubmission2: Yup.string().nullable().optional(),
    claimDescription: Yup.string().required('Claim Description is required'),
    idType: Yup.string().required('Id Type is required'),
    idNumber: Yup.string().required('Id Number is required'),
  });
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      contactNumber: '',
      claim: '',
      incidentTime: '',
      location: '',
      claimDocumentSubmission1: '',
      claimDocumentSubmission2: '',
      claimDescription: '',
      idType: '',
      idNumber: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      sendRequest({
        ...values,
        claimDocumentSubmission1: await fileToBase64(
          values.claimDocumentSubmission1
        ),
        claimDocumentSubmission2: await fileToBase64(
          values.claimDocumentSubmission2
        ),
      });
    },
  });

  const [status, setStatus] = useState(null); // loading, success, error
  const [errorMessage, setErrorMessage] = useState('');
  const sendRequest = async (values) => {
    setStatus('loading');

    try {
      const response = await fetch(
        'https://hooks.zapier.com/hooks/catch/20237045/2d0yso3/',
        {
          method: 'POST',
          body: JSON.stringify(values),
        }
      );
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Something went wrong');
      }
      setStatus('success');
    } catch (error) {
      setStatus('error');
      setErrorMessage(error.message);
    }
  };

  const fileToBase64 = (file) => {
    if (!file) return;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const reset = () => {
    formik.resetForm();
    setStatus(null);
  };

  return (
    <div>
      <Header />
      <section>
        <div className="container">
          <div className="form-section make-a-claim">
            {status === 'success' ? (
              <>
                <div className="flex flex-col items-center justify-center text-center mb-8">
                  <img
                    src={icon_user_avatar}
                    alt=""
                    width="60"
                    height="60"
                    className="avatar-img"
                  />
                  <h3>Claim Submitted Successfully!</h3>
                  <p>
                    Our team will get back to you as soon as possible. <br />{' '}
                    Thank you for choosing Casava.
                  </p>

                  <div className="flex justify-center items-center flex-wrap">
                    <button
                      className="button button--black mt-8"
                      onClick={reset}
                    >
                      <span>Submit Another Claim</span>
                    </button>
                    <a
                      href="https://insurance.casava.co/signup/"
                      target="_blank"
                      rel="noreferrer"
                      className="button button--pink mt-8"
                    >
                      <span>View Other Products</span>
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <form
                name="make-a-claim"
                noValidate
                onSubmit={formik.handleSubmit}
              >
                <div className="flex flex-col items-center justify-center text-center mb-8">
                  <img
                    src={icon_user_avatar}
                    alt=""
                    width="60"
                    height="60"
                    className="avatar-img"
                  />
                  <h4>
                    Fill in the form below to <br /> register a claim with
                    Casava.
                  </h4>
                  <p>Our team will get back to you as soon as possible.</p>
                </div>

                <div>
                  <div className="flex flex-col lg:flex-row">
                    <div className="form-group">
                      <label htmlFor="firstName">First Name *</label>
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="Firt Name"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                      />

                      <p className="danger-form-validation">
                        {formik.touched.firstName && formik.errors.firstName ? (
                          <>{formik.errors.firstName}</>
                        ) : null}
                      </p>
                    </div>

                    <div className="form-group">
                      <label htmlFor="lastName">Last Name *</label>
                      <input
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                      />

                      <p className="danger-form-validation">
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <>{formik.errors.lastName}</>
                        ) : null}{' '}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col lg:flex-row">
                    <div className="form-group">
                      <label htmlFor="email">Email address *</label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email address"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />

                      <p className="danger-form-validation">
                        {formik.touched.email && formik.errors.email ? (
                          <>{formik.errors.email}</>
                        ) : null}{' '}
                      </p>
                    </div>

                    <div className="form-group">
                      <label htmlFor="contactNumber">Contact Number *</label>
                      <input
                        type="text"
                        name="contactNumber"
                        id="contactNumber"
                        placeholder="Contact Number"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.contactNumber}
                      />

                      <p className="danger-form-validation">
                        {formik.touched.contactNumber &&
                        formik.errors.contactNumber ? (
                          <>{formik.errors.contactNumber}</>
                        ) : null}{' '}
                      </p>
                    </div>
                  </div>

                  <div className="form-group">
                    <label htmlFor="claim">Claim *</label>
                    <select
                      type="text"
                      name="claim"
                      id="claim"
                      placeholder="Claim"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.claim}
                    >
                      <option value="">Select Claim Type</option>
                      <option value="ACCIDENT">Accident</option>
                      <option value="MEDICAL_EMERGENCY">
                        Medical Emergency
                      </option>
                      <option value="DISABILITY">Disability</option>
                    </select>

                    <p className="danger-form-validation">
                      {formik.touched.claim && formik.errors.claim ? (
                        <>{formik.errors.claim}</>
                      ) : null}{' '}
                    </p>
                  </div>

                  <div className="form-group">
                    <label htmlFor="incidentTime">Incident Time *</label>
                    <input
                      type="datetime-local"
                      name="incidentTime"
                      id="incidentTime"
                      placeholder="Incident Time"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.incidentTime}
                    />

                    <p className="danger-form-validation">
                      {formik.touched.incidentTime &&
                      formik.errors.incidentTime ? (
                        <>{formik.errors.incidentTime}</>
                      ) : null}{' '}
                    </p>
                  </div>

                  <div className="form-group">
                    <label htmlFor="location">Location *</label>
                    <input
                      type="text"
                      name="location"
                      id="location"
                      placeholder="Location"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.location}
                    />

                    <p className="danger-form-validation">
                      {formik.touched.location && formik.errors.location ? (
                        <>{formik.errors.location}</>
                      ) : null}{' '}
                    </p>
                  </div>

                  <div className="form-group">
                    <label htmlFor="claimDocumentSubmission1">
                      Claim Document Submission
                    </label>
                    <div className="flex flex-col lg:flex-row">
                      <div>
                        <p className="text-xs" style={{ marginBottom: '2px' }}>
                          Required *
                        </p>
                        <input
                          type="file"
                          name="claimDocumentSubmission1"
                          id="claimDocumentSubmission1"
                          placeholder="Location"
                          required
                          onChange={(e) =>
                            formik.setFieldValue(
                              'claimDocumentSubmission1',
                              e.target.files[0]
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <div>
                        <p className="text-xs" style={{ marginBottom: '2px' }}>
                          Optional
                        </p>
                        <input
                          type="file"
                          name="claimDocumentSubmission2"
                          id="claimDocumentSubmission2"
                          placeholder="Location"
                          required
                          onChange={(e) =>
                            formik.setFieldValue(
                              'claimDocumentSubmission2',
                              e.target.files[0]
                            )
                          }
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>

                    <p className="danger-form-validation">
                      {formik.touched.claimDocumentSubmission1 &&
                      formik.errors.claimDocumentSubmission1 ? (
                        <>{formik.errors.claimDocumentSubmission1}</>
                      ) : null}{' '}
                    </p>
                  </div>

                  <div className="form-group">
                    <label htmlFor="claimDescription">
                      Claim Description *
                    </label>
                    <textarea
                      type="text"
                      name="claimDescription"
                      id="claimDescription"
                      placeholder="Location"
                      required
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.claimDescription}
                    />

                    <p className="danger-form-validation">
                      {formik.touched.claimDescription &&
                      formik.errors.claimDescription ? (
                        <>{formik.errors.claimDescription}</>
                      ) : null}{' '}
                    </p>
                  </div>

                  <div className="flex flex-col lg:flex-row">
                    <div className="form-group">
                      <label htmlFor="idType">Id Type *</label>
                      <select
                        type="text"
                        name="idType"
                        id="idType"
                        placeholder="Id Type"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.idType}
                      >
                        <option value="">Select ID Type</option>
                        <option value="BVN">Bank Verification Number</option>
                        <option value="NIN">National ID</option>
                        <option value="PASSPORT">Passport</option>
                        <option value="DRIVERS_LICENSE">
                          Driver's License
                        </option>
                      </select>

                      <p className="danger-form-validation">
                        {formik.touched.idType && formik.errors.idType ? (
                          <>{formik.errors.idType}</>
                        ) : null}{' '}
                      </p>
                    </div>

                    <div className="form-group">
                      <label htmlFor="idNumber">ID Number *</label>
                      <input
                        type="text"
                        name="idNumber"
                        id="idNumber"
                        placeholder="ID Number"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.idNumber}
                      />

                      <p className="danger-form-validation">
                        {formik.touched.idNumber && formik.errors.idNumber ? (
                          <>{formik.errors.idNumber}</>
                        ) : null}{' '}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <button
                    type="submit"
                    className="button button--black mt-8"
                    value="Submit"
                    {...(status === 'loading' && {
                      disabled: true,
                      style: {
                        cursor: 'not-allowed',
                        opacity: 0.5,
                        pointerEvents: 'none',
                      },
                    })}
                  >
                    {status === 'loading' ? (
                      <span>...</span>
                    ) : (
                      <span>Submit</span>
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
