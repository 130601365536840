import icon_green_check from '../../assets/green-check.svg';

function WhyCasava() {
  return (
    <>
      <section
        className="health-steps-section"
        style={{ background: 'rgba(250 0 150 / 0.1)' }}
      >
        <div className="container">
          <div className="grid gap-10 grid-cols-1 md:grid-cols-2 md:gap-12">
            <div className="flex flex-col">
              <h2 className="mb-8">Why Casava?</h2>
              <h3 className="mb-4">Innovation Meets Trust.</h3>
              <p className="text-xl">
                At Casava, we're more than just insurance. We're a revolution in
                peace of mind. Here's why:
              </p>
              <div className="mt-8 hidden md:block">
                <a
                  href="/"
                  target="_blank"
                  className="button button--black"
                >
                  <span>Discover the Difference</span>
                </a>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="step-item">
                <img src={icon_green_check} alt="checkmark" />
                <div className="ml-8">
                  <h5>Fast Claims Processing</h5>
                  <p>48 hours is all it takes to get back to what you love.</p>
                </div>
              </div>
              <div className="step-item">
                <img src={icon_green_check} alt="checkmark" />
                <div className="ml-8">
                  <h5>Comprehensive Coverage</h5>
                  <p>We protect every facet of your event experience.</p>
                </div>
              </div>
              <div className="step-item">
                <img src={icon_green_check} alt="checkmark" />
                <div className="ml-8">
                  <h5>Customer-Centric Approach</h5>
                  <p>We're here, 24/7, because life doesn't wait.</p>
                </div>
              </div>
              <div className="step-item">
                <img src={icon_green_check} alt="checkmark" />
                <div className="ml-8">
                  <h5>Digital Convenience</h5>
                  <p>Your protection, always at your fingertips.</p>
                </div>
              </div>
              <div className="mt-8 md:hidden">
                <a
                  href="/"
                  target="_blank"
                  className="button button--black"
                >
                  <span>Discover the Difference</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default WhyCasava;
