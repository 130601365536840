function Hospitals() {
  return (
    <>
      {/* hospitals section */}
      <section className="hospitals-section">
        <div className="container">
          <div className="flex flex-col lg:flex-row items-center justify-between">
            <div className="w-full lg:w-1/3">
              <h5 className="font-sans mb-6">
                Over 400 hospitals just for you
              </h5>
              <a
                className="button button--black"
                href="https://insurance.casava.co/signup/health/"
                target="_blank"
                rel='noreferrer'
              >
                <span>Buy Health Insurance</span>
              </a>
            </div>
            <div className="hospitals-carousel-container w-full lg:w-2/3">
              <div className="hospitals-carousel__wrapper">
                <div className="carousel-item">Tender Touch</div>
                <div className="carousel-item">St Joseph</div>
                <div className="carousel-item">First Graceland</div>
                <div className="carousel-item">Westcare Specialist </div>
                <div className="carousel-item">Vintage </div>
                <div className="carousel-item">Living Spring </div>
                <div className="carousel-item">Regal </div>
                <div className="carousel-item">Prestige </div>
                <div className="carousel-item">Ason </div>
                <div className="carousel-item">Veta </div>
                <div className="carousel-item">Wellrose </div>
                <div className="carousel-item">Medwin </div>
                <div className="carousel-item">Queens Specialist </div>
                <div className="carousel-item">Horizon </div>
                <div className="carousel-item">Sybron </div>
                <div className="carousel-item">Springbloom </div>
                <div className="carousel-item">Sophike </div>
                <div className="carousel-item">Liberty </div>
                <div className="carousel-item">& many more</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hospitals;
