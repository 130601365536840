//

export default function Empowerment() {
  return (
    <div className="empowerment-banner">
      <div className="container">
        <div>
          ✨ For the #HerMoneyHerPower campaign, five women share how insurance
          restored their financial power.{' '}
          <a
            href="https://www.blog.casava.com/p/5-women-reveal-how-insurance-secured"
            target="_blank"
            rel="noreferrer"
          >
            Read more here
          </a>
        </div>
      </div>
    </div>
  );
}
