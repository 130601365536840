import ctaProtectMyIncome from '../../assets/cta-protect-my-income.png';
import ctaInsuranceMyHealth from '../../assets/cta-insure-my-health.png';
import ctaGetHealthCash from '../../assets/cta-get-health-cash.png';

function Cover() {
  return (
    <>
      {/* cover section  */}
      <section className="hero-section md:bg-pink-300 landing-page">
        <div className="container">
          <div className="grid justify-center gap-10 grid-cols-1 md:grid-cols-2 md:gap-20">
            <div>
              <h1>
                Insurance
                <br />
                you enjoy
              </h1>
              <p className="text-lg md:text-xl mr-0 md:mr-10">
                Focus on living, while we cover the <br /> things you love
              </p>
              <a
                href="https://insurance.casava.co/signup/"
                target="_blank"
                rel="noreferrer"
                aria-label="Buy Insurance"
                className="button button--white mt-6"
              >
                <span>Buy Insurance</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* get cover section  */}
      <section className="value-props-section">
        <div className="container">
          <div className="flex justify-center flex-col items-center text-center">
            <div className="w-full lg:w-3/5">
              <h1 className="text-center mb-6">
                {' '}
                We've also got amazing prices
              </h1>
              <p className="text-center text-2xl">
                Get insured for less than the <br /> cost of a taxi ride.
              </p>
            </div>
            <div
              className="w-full justify-center"
              style={{ display: 'flex', flexWrap: 'wrap', margin: '2em 0' }}
            >
              <div
                className="card-item mr-auto "
                style={{ width: '390px', height: '300px' }}
              >
                <img src={ctaInsuranceMyHealth} alt="Insure my health" />
                <div className="mt-auto">
                  <h3 className="mb-4">Insure my health</h3>
                  <p className="mb-8">
                    Enjoy affordable healthcare without breaking the bank
                  </p>
                  <a href="/health-insurance" aria-label="Explore Health">
                    Explore Health
                  </a>
                </div>
              </div>

              <div
                className="card-item mr-auto"
                style={{ width: '390px', height: '300px' }}
              >
                <img src={ctaGetHealthCash} alt="Get health cash" />
                <div className="mt-auto">
                  <h3 className="mb-4">Get health cash</h3>
                  <p className="mb-8">
                    Get cash back for every visit to the hospital
                  </p>
                  <a href="/health-cash" aria-label="Explore Health Cash">
                    Explore Health Cash
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Cover;
