import umbrellaCover from '../../assets/umbrella-cover.png';

function Banner() {
  return (
    <>
      {/* banner section  */}
      <section className="footer-cta">
        <div className="container">
          <div className="footer-cta__card px-12 py-12 md:px-20 md:py-16">
            <h2 className="mb-4">We're Here For You</h2>
            <p className="md:w-2/4 w-full">Support That's Always On.</p>
            <a
              href="/make-a-claim"
              target="_blank"
              rel="noreferrer"
              className="button button--white mt-6"
              aria-label="Get Started"
            >
              <span>Get Started</span>
            </a>
            <img
              src={umbrellaCover}
              className="footer-cta__umbrella hidden md:block"
              alt="umbrella cover"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
