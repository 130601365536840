import Header from '../components/Common/Header';
import HealthPerks from '../components/Common/HealthPerks';
import Cover from '../components/EventBoard/Cover';
import icon_health_insurance_steps from '../assets/icon-health-iinsurance-steps.png';
import EffortlessClaims from '../components/EventBoard/EffortlessClaims';
import WhyCasava from '../components/EventBoard/WhyCasava';
import Faqs from '../components/EventBoard/Faqs';
import Banner from '../components/EventBoard/Banner';
import Footer from '../components/Common/Footer';
import EnjoyTheShow from '../components/EventBoard/EnjoyTheShow';

export default function EventBoardPage() {
  let healthPerksInfo = {
    title: 'Enjoy the Show, We Got You',
    desc1: '',
    desc2:
      "As an attendee of IMT 3.0, you are automatically protected by Casava's accident insurance. This coverage includes:",
    perks_three: [
      {
        label: 'Accidental Injury Coverage',
        desc: 'Medical expenses related to any accidental injuries sustained during the event.',
        img: icon_health_insurance_steps,
      },
      {
        label: 'Emergency Medical Assistance',
        desc: 'On-site medical support and transportation to the nearest healthcare facility if required.',
        img: icon_health_insurance_steps,
      },
      {
        label: 'Personal Belongings Protection',
        desc: 'Coverage for loss or damage to personal items due to accidents.',
        img: icon_health_insurance_steps,
      },
      {
        label: 'Liability Coverage',
        desc: 'Protection in case of accidental harm caused to others during the event.',
        img: icon_health_insurance_steps,
      },
    ],
    page: 'eventboard',
    note: (
      <>
        Please review the full terms and conditions of your coverage, available{' '}
        <a
          href="/terms"
          target="_blank"
          className="text-pink"
          style={{ textDecoration: 'underline' }}
        >
          here
        </a>
        .
      </>
    ),
  };
  const faqs1 = {
    rows: [
      {
        title: 'What should I do if I get injured at the event?',
        content: 'Seek immediate on-site medical assistance, then submit your claim online with Casava.',
      },
      {
        title: 'How fast will my claim be processed?',
        content: 'In 48 hours or less. Because your time matters.',
      },
    ],
  };
  const faqs2 = {
    rows: [
      {
        title: 'What if I lost personal belongings at the event?',
        content: 'Covered. Submit a detailed claim, and we’ll handle the rest.',
      },
      {
        title: 'Can I track my claim?',
        content: 'Absolutely. Log in to track your claim in real-time.',
      },
    ],
  };
  return (
    <div>
      <Header />
      <Cover />
      <EnjoyTheShow />
      <EffortlessClaims />
      <WhyCasava />
      <Faqs data1={faqs1} data2={faqs2}></Faqs>
      <Banner />
      <Footer />
    </div>
  );
}
