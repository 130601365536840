import React, { useEffect, useState } from 'react';
import Header from '../components/Common/Header';
import Faq from '../components/Common/Faq';
import Banner from '../components/Common/Banner';
import Footer from '../components/Common/Footer';
import Cover from '../components/HealthCash/Cover';
import HealthPerk from '../components/Common/HealthPerks';
import InfoBanner from '../components/Common/InfoBanner';
import SplitBanner from '../components/Common/SplitBannerClaims';
// import icon_health_care from '../assets/icon-health-cash.svg';
import icon_health_care from '../assets/icon-policy-health-cash-large.png';
import icon_health_insurance_steps from '../assets/icon-health-iinsurance-steps.png';
import SplitBannerHealthCash from '../components/Common/SplitBannerHealthCash';
import icon_close from '../assets/icon-close.png';
import icon_user_avatar from '../assets/avatar.png';
import icon_check from '../assets/icon-check.png';
import icon_user_name from '../assets/icon-user-name.png';
import icon_user_email from '../assets/icon-user-email.png';
import icon_user_phone from '../assets/icon-user-name.png';
import icon_whatsapp from '../assets/icon-whatsapp.png';
import { useFormik } from 'formik';
import axios from 'axios';

function HealthCash() {
  const [isAdded, setisAdded] = useState(false);
  const [isModal, setModal] = useState(false);
  const [isSuccess, setisSuccess] = useState(false);
  const [successMessage, setsuccessMessage] = useState('');
  const [isError, setisError] = useState(false);
  const [errorMessage, seterrorMessage] = useState('');
  const [isInfoBanner, setisInfoBanner] = useState(false);

  let infoProps = {
    field1: 'Paying you for ',
    field2: 'healthcare',
    desc1:
      'Health cash provides you with cash back whenever you get treated at ',
    desc2: "the hospital. We'll pay your claims within 48 hours",
    desc3: '',
    img: icon_health_care,
  };

  let healthPerksInfo = {
    title: 'Enjoy cash benefits after every treatment',
    desc1: 'Focus on enjoying life while we focus on providing a',
    desc2: 'cover for the things you love.',
    perks_three: [
      {
        label: 'Accident care & treatment',
        desc: 'Get cash back if you receive treatment due to an accident at home, at work, or on the road',
        img: icon_health_insurance_steps,
      },
      {
        label: 'Hospitalization',
        desc: 'Get cash back for the cost of admission at the hospital for 1 or more days',
        img: icon_health_insurance_steps,
      },
      {
        label: 'Permanent injury',
        desc: 'Get cash back for the cost of injury that renders you permanently disabled or unable to work',
        img: icon_health_insurance_steps,
      },
      // {"label":"Malaria","desc":"Get reimbursed if you are infected with Malaria","img":icon_health_insurance_steps},
      // {"label":"Covid19 infection","desc":"Get reimbursed if you are infected with covid19 and can’t work.","img":icon_health_insurance_steps}
    ],
  };

  // const onChange = (valueEvent, nameEvent, val ) => {
  //     const value = val || valueEvent;
  //     const name = nameEvent;

  //     setData((prevValue) => ({
  //         ...prevValue,
  //         [name]: value,
  //     }));
  // };

  // const handleChange = (e) => {
  //     onChange(e.target.value, e.target.name);
  // };

  const submitForm = (data) => {
    setisSuccess(false);
    setisError(false);
    setsuccessMessage('');
    seterrorMessage('');
    setisAdded(false);

    let config = new Headers();
    config.append('Accept', 'application/json');
    config.append('Content-Type', 'application/json');

    var raw = JSON.stringify(data);

    axios
      .post(
        'https://api.dev.casava.co/api/local/subscribe-to-waitlist',
        data,
        config
      )
      .then(function (response) {
        console.log(response);
        setisSuccess(true);
        setisAdded(true);
        formik.resetForm();
      })
      .catch(function (error) {
        console.log(error);
        console.log(error.response.data.errors);
        console.log(error.response.data.message);
        setisError(true);
        seterrorMessage(
          error.response.data.message
            ? error.response.data.message
            : 'Error while adding data'
        );
      });

    // fetch("https://api.dev.casava.co/api/local/subscribe-to-waitlist", requestOptions)
    //   // .then(response => {
    //   //   response.text();
    //   //   console.log(response);
    //   //   console.log("here is  my response......");
    //   //   setisAdded(true)
    //   // })
    //   .then(response => {
    //     response.text();
    //     console.log(response);
    //   })
    //   .then(result => {console.log(result)})
    //   .catch(error => console.log('error', error));
  };

  const handleClose = () => {
    setModal(false);
    setisAdded(false);
    setisSuccess(false);
    setisError(false);
    setsuccessMessage('');
    seterrorMessage('');
    setisInfoBanner(false);
    formik.resetForm();
  };

  const validate = (values) => {
    const errors = {};
    if (!values.first_name) {
      errors.first_name = 'Required';
    }
    if (!values.last_name) {
      errors.last_name = 'Required';
    }

    if (!values.phone) {
      errors.phone = 'Required';
    }
    if (!values.email) {
      errors.email = 'Required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }
    return errors;
  };

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      product: 'Health Insurance',
      send_whatsapp: false,
    },
    validate,
    onSubmit: (values) => {
      console.log(values);
      submitForm(values);
    },
  });

  return (
    <div>
      <Header />
      <Cover setModal={setModal} />
      <HealthPerk
        healthPerksInfo={healthPerksInfo}
        showBtn={true}
        setModal={setModal}
        setisInfoBanner={setisInfoBanner}
      />
      <InfoBanner infoProps={infoProps} />
      <SplitBannerHealthCash />
      <Banner ctaHref="https://insurance.casava.co/signup/health-cash/" />
      <Footer />

      {isModal && (
        <section className={isInfoBanner ? 'modal-perks' : 'modal-cover'}>
          <div className="modal modal-health-cash">
            <div className="bg-modal-health-cash">
              <span onClick={handleClose} className="modal-close">
                <img src={icon_close} />
              </span>
              <div className="form-section">
                {isAdded ? (
                  <>
                    <div className="form-pseudo">
                      <img src={icon_user_avatar} />
                      <h3>
                        Enjoy premium healthcare <br /> with Casava.
                      </h3>
                      <p>
                        Be the first to try this seamless <br /> health cash
                      </p>
                      <span className="success-icon">
                        <img
                          src={icon_check}
                          className="form-icon-green-large"
                        />
                      </span>
                      <h4>Success!</h4>
                      <p>
                        <small>
                          Congratulations, you have joined the <br /> waiting
                          list
                        </small>
                      </p>
                      <a className="button button--pink" onClick={handleClose}>
                        <span>Done</span>
                      </a>
                    </div>
                  </>
                ) : (
                  <form
                    name="health-cash-form"
                    noValidate
                    onSubmit={formik.handleSubmit}
                  >
                    <img src={icon_user_avatar} />
                    <h3>
                      Enjoy premium healthcare <br /> with Casava.
                    </h3>
                    <p>
                      Be the first to try this seamless <br /> health cash
                    </p>

                    {isError && <div>{errorMessage}</div>}

                    <div className="field field-half first-child">
                      <img
                        src={icon_user_name}
                        className="form-placeholder-icon"
                      />
                      <input
                        type="text"
                        name="first_name"
                        id="first_name"
                        placeholder="Firt Name"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.first_name}
                      />
                      <label htmlFor="first_name">First Name</label>

                      <p className="danger-form-validation">
                        {formik.touched.first_name &&
                        formik.errors.first_name ? (
                          <>{formik.errors.first_name}</>
                        ) : null}
                      </p>
                    </div>

                    <div className="field field-half last-child">
                      <img
                        src={icon_user_name}
                        className="form-placeholder-icon"
                      />
                      <input
                        type="text"
                        name="last_name"
                        id="last_name"
                        placeholder="Last Name"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.last_name}
                      />
                      <label htmlFor="last_name">Last Name</label>

                      <p className="danger-form-validation">
                        {formik.touched.last_name && formik.errors.last_name ? (
                          <>{formik.errors.last_name}</>
                        ) : null}{' '}
                      </p>
                    </div>

                    <div className="field field-full">
                      <img
                        src={icon_user_email}
                        className="form-placeholder-icon"
                      />
                      <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email address"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                      />
                      <label htmlFor="email">Email address</label>

                      <p className="danger-form-validation">
                        {formik.touched.email && formik.errors.email ? (
                          <>{formik.errors.email}</>
                        ) : null}{' '}
                      </p>
                    </div>

                    <div className="field field-full">
                      <img
                        src={icon_user_phone}
                        className="form-placeholder-icon"
                      />
                      <input
                        type="text"
                        name="phone"
                        id="phone"
                        placeholder="Phone number"
                        required
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phone}
                      />
                      <label htmlFor="phone">Phone number</label>

                      <p className="danger-form-validation">
                        {formik.touched.phone && formik.errors.phone ? (
                          <>{formik.errors.phone}</>
                        ) : null}{' '}
                      </p>
                    </div>

                    <div className="info">
                      {' '}
                      <img src={icon_whatsapp} className="form-icon-green" /> We
                      recommend you use a Whatsapp phone number
                    </div>

                    {/* <input type="submit" className="button button--black" name="joinwaitlist" value="Join Waitlist" /> */}

                    <button
                      type="submit"
                      className="button "
                      value="Join Waitlist"
                    >
                      Join Waitlist
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default HealthCash;
