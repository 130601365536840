import FaqComponent from 'react-faq-component';

function Faqs(props) {
  // console.log(props);
  const data1 = props.data1 ? props.data1 : {};
  const data2 = props.data2 ? props.data2 : [];

  return (
    <>
      {/* faq section */}
      <div>
        <section className="faq-section">
          <div className="container">
            <div className="flex flex-col justify-items-center justify-center items-center lg:text-center">
              <h1 className="mb-8 w-full lg:w-2/3">
                Simple Answers to Important Questions
              </h1>
              <h3 className='mb-16'>Empower Yourself with Knowledge</h3>
            </div>

            <FaqComponent data={data1} />
            <FaqComponent data={data2} />

            <div className="text-center mt-8">
              <a
                href="/make-a-claim"
                target="_blank"
                className="button button--black mt-5"
              >
                <span>Get Answers, Fast</span>
              </a>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default Faqs;
