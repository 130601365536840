import icon_health_insurance_steps from '../../assets/icon-health-iinsurance-steps.png';

function EnjoyTheShow(props) {
  const perks = [
    {
      label: 'Accidental Injury Coverage',
      desc: 'Medical expenses related to any accidental injuries sustained during the event.',
      img: icon_health_insurance_steps,
    },
    {
      label: 'Emergency Medical Assistance',
      desc: 'On-site medical support and transportation to the nearest healthcare facility if required.',
      img: icon_health_insurance_steps,
    },
    {
      label: 'Personal Belongings Protection',
      desc: 'Coverage for loss or damage to personal items due to accidents.',
      img: icon_health_insurance_steps,
    },
    {
      label: 'Liability Coverage',
      desc: 'Protection in case of accidental harm caused to others during the event.',
      img: icon_health_insurance_steps,
    },
  ];
  return (
    <>
      {/* health perks section */}
      <section className="health-perks-section">
        <div className="container">
          <div className="flex flex-col items-center justify-center">
            <div className="w-full lg:w-2/3 lg:text-center">
              <h1 className="mb-6">Enjoy the Show, <br /> We Got You</h1>
              {/* <h3 className="mb-8">Your Coverage Details</h3> */}
              <p className="text-xl">
                As an attendee of IMT 3.0, you are automatically protected by
                Casava's accident insurance. This coverage includes:
              </p>
            </div>
          </div>
          <div className="coverage-features coverage-features-two">
            {perks.map((element, index) => {
              return (
                <div className="perk-item" key={index}>
                  <img src={icon_health_insurance_steps} alt="" />
                  <h5>{element.label}</h5>
                  <p>{element.desc}</p>
                </div>
              );
            })}
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-center">
              Please review the full terms and conditions of your coverage,
              available{' '}
              <a
                href="/terms"
                target="_blank"
                className="text-pink"
                style={{ textDecoration: 'underline' }}
              >
                here
              </a>
              .
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default EnjoyTheShow;
